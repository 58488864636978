import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Flashcard from "../../components/Card/Flashcard";
import {
  ButtonGroup,
  Button,
  Typography,
  TextField,
  Modal,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { FlashcardType } from "../../type";
import { useParams } from "react-router-dom";
import { fetchDeckCards, visitCard } from "./flashcardService";
import { isAxiosError } from "axios";
import CreateCard from "../../components/Card/CreateCard";
import EditCard from "../../components/Card/EditCard";
import DeleteCard from "../../components/Card/DeleteCard";

const defaultTheme = createTheme();

export default function Study() {
  const [editCardOpen, setEditCardOpen] = useState(false);
  const [createCardOpen, setCreateCardOpen] = useState(false);
  const [deleteCardOpen, setDeleteCardOpen] = useState(false);

  const handleEditCardOpen = () => setEditCardOpen(true);
  const handleEditCardClose = () => setEditCardOpen(false);

  const handleCreateCardOpen = () => setCreateCardOpen(true);
  const handleCreateCardClose = () => setCreateCardOpen(false);

  const handleDeleteCardOpen = () => setDeleteCardOpen(true);
  const handleDeleteCardClose = () => setDeleteCardOpen(false);

  const params = useParams();
  const deck_id = params.id ? parseInt(params.id, 10) : -1;

  const [flashcards, setFlashcards] = useState<FlashcardType[]>([]);
  const [isShown, setIsShown] = useState(false);
  const emptyCard = {
    deck_id: -1,
    question: "",
    answer: "",
    tag: "",
  };
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [currentCard, setCurrentCard] = useState<FlashcardType>(emptyCard);

  const refresh = async () => {
    try {
      const cards: FlashcardType[] = await fetchDeckCards(deck_id);
      setFlashcards(cards);
      if (cards.length !== 0) {
        setCurrentCard(cards[currentIdx]);
        if (!cards[currentIdx].answer) {
          setIsShown(true);
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Error occurred when fetching Deck Cards ${error}`);
      }
    }
  };

  useEffect(() => {
    refresh();
  }, []);
  const handleShowAnswer = () => {
    setIsShown(true);
  };

  const handleNextCard = async (difficulty: number) => {
    try {
      const queryCard: FlashcardType = {
        ...currentCard,
        difficulty: difficulty,
      };
      await visitCard(queryCard);
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Error occurred when visting card ${error}`);
      }
    }
    setCurrentIdx(currentIdx + 1);
    if (currentIdx + 1 < flashcards.length) {
      setCurrentCard(flashcards[currentIdx + 1]);
      if (!flashcards[currentIdx + 1].answer) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    }
  };

  const handleDeleteCard = () => {
    setCurrentIdx(currentIdx + 1);
    if (currentIdx + 1 < flashcards.length) {
      setCurrentCard(flashcards[currentIdx + 1]);
      if (!flashcards[currentIdx + 1].answer) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    }
  };

  if (flashcards.length === 0 || currentIdx >= flashcards.length) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 8,
          height: "100%",
          width: "100%",
        }}
      >
        <Typography variant="h4" component="div">
          Congratulations, You have finished all of the cards for this deck!
        </Typography>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="success"
          onClick={handleCreateCardOpen}
        >
          Add More
        </Button>
        <CreateCard
          open={createCardOpen}
          onClose={handleCreateCardClose}
          deck_id={deck_id!}
        />
      </Box>
    );
  }
  const handleEditCard = (updatedCard: FlashcardType) => {
    setFlashcards((prevFlashcards) =>
      prevFlashcards.map((card) =>
        card.card_id === updatedCard.card_id ? updatedCard : card
      )
    );
    setCurrentCard(updatedCard);
  };

  return (
    <Box
      sx={{
        pt: 2,
        width: "60vw",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="body1" sx={{ alignSelf: "flex-end", mb: 2 }}>
        <span style={{ fontWeight: "bold", color: "blue" }}>
          {flashcards.length - currentIdx}
        </span>{" "}
        More cards to go!
      </Typography>
      <Box
        sx={{
          width: "60vw",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button variant="contained" onClick={handleEditCardOpen}>
          Edit
        </Button>
        <Box>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            color="success"
            onClick={handleCreateCardOpen}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteCardOpen}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <EditCard
        currentCard={currentCard}
        open={editCardOpen}
        onClose={handleEditCardClose}
        onEdit={handleEditCard}
      />
      <CreateCard
        open={createCardOpen}
        onClose={handleCreateCardClose}
        deck_id={deck_id!}
      />
      <DeleteCard
        cardId={currentCard.card_id!}
        open={deleteCardOpen}
        onClose={handleDeleteCardClose}
        onDelete={handleDeleteCard}
      />
      <Flashcard
        isShown={isShown}
        question={currentCard?.question || ""}
        answer={currentCard?.answer || ""}
      />
      <ButtonGroup
        sx={{ mt: 2, gap: 5 }}
        variant="outlined"
        aria-label="flashcard options"
      >
        {!isShown ? (
          <Button onClick={handleShowAnswer}>Show Answer</Button>
        ) : (
          <>
            <Button
              onClick={() => {
                handleNextCard(1);
              }}
            >
              Hard
            </Button>
            <Button
              onClick={() => {
                handleNextCard(0);
              }}
            >
              Good
            </Button>
            <Button
              onClick={() => {
                handleNextCard(-1);
              }}
            >
              Easy
            </Button>
          </>
        )}
      </ButtonGroup>
    </Box>
  );
}
