import axios from "axios";
import { Deck } from "../../type";
import { getJWTHeader } from "../../utils/utils";

import { BASE_URL } from "../base_url";

export const fetchUserDecks = async (username: string) : Promise<Deck[]> => {
    try {
        const response = await axios.get<Deck[]>(`${BASE_URL}/decks/getDecks/${username}`, {
            headers: getJWTHeader(),
        });
        console.log("Fetch deck successful");
        return response.data;
    } catch (error: any) {
        console.error("Error when fetching decks:", error);
        throw new Error(error.response?.data?.message || "Deck retrieving failed");
    }
}

export const createDeck = async (deck: Deck) => {
    try {
        await axios.post(`${BASE_URL}/decks/createDeck`, deck, {
            headers: getJWTHeader(),
        });
        console.log("Create deck successful");
    } catch (error: any) {
        console.error("Error during deck creation:", error);
        throw new Error(error.response?.data?.message || "Deck creation failed");
    }
}

export const editDeck = async (deck: Deck) => {
    try {
        await axios.post(`${BASE_URL}/decks/updateDeck`, deck, {
            headers: getJWTHeader(),
        });
        console.log("Update deck successful");
    } catch (error: any) {
        console.error("Error during deck update:", error);
        throw new Error(error.response?.data?.message || "Deck update failed");
    }
}


export const deleteDeck = async (deck_id: number) => {
    try {
        await axios.post(`${BASE_URL}/decks/deleteDeck`, { deck_id }, {
            headers: getJWTHeader(),
        });
        console.log("Delete deck successful");
    } catch (error: any) {
        console.error("Error during deck deletion:", error);
        throw new Error(error.response?.data?.message || "Deck deletion failed");
    }
}
