import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { FlashcardType } from "../../type";
import { updateCard } from "../../pages/Study/flashcardService";
import { isAxiosError } from "axios";

const defaultTheme = createTheme();

interface EditCardProps {
  currentCard: FlashcardType;
  open: boolean;
  onClose: () => void;
  onEdit: (updatedCard: FlashcardType) => void; // Callback to handle the edit submission
}

const EditCard: React.FC<EditCardProps> = ({
  currentCard,
  open,
  onClose,
  onEdit,
}) => {
  const [editedCard, setEditedCard] = useState<FlashcardType>(currentCard);

  useEffect(() => {
    setEditedCard(currentCard);
  }, [currentCard]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSaveEdit = async () => {
    onEdit(editedCard);
    try {
      await updateCard(editedCard);
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Error occurred when updating card ${error}`);
      }
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-card-modal-title"
      aria-describedby="edit-card-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="edit-card-modal-title"
          variant="h5"
          component="h2"
          sx={{ mb: 2 }}
        >
          Edit Card
        </Typography>
        <ThemeProvider theme={defaultTheme}>
          <TextField
            fullWidth
            label="Front"
            variant="outlined"
            value={editedCard.question}
            sx={{ mb: 2 }}
            onChange={(e) =>
              setEditedCard({ ...editedCard, question: e.target.value })
            }
            rows={2}
          />
          <TextField
            fullWidth
            label="Back"
            variant="outlined"
            value={editedCard.answer}
            sx={{ mb: 2 }}
            onChange={(e) =>
              setEditedCard({ ...editedCard, answer: e.target.value })
            }
            multiline
            rows={5}
          />
        </ThemeProvider>
        <Button sx={{ mt: 2 }} variant="contained" onClick={handleSaveEdit}>
          Save
        </Button>
        <Button
          sx={{ mt: 2, ml: 2 }}
          variant="outlined"
          color="error"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default EditCard;
