import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

interface FlashcardProp {
  isShown: boolean;
  question: string;
  answer: string;
}

export default function Flashcard({
  isShown,
  question,
  answer,
}: FlashcardProp) {
  return (
    <>
      <Card sx={{ width: "60vw", height: "60vh", textAlign: "center" }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {question}
          </Typography>
          {isShown && (
            <Typography variant="body1" sx={{ mt: 2 }}>
              {answer}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
}
