import axios from "axios";
import { BASE_URL } from "../base_url";

interface SignInForm {
    username: string;
    password: string;
}
const signInService = async (form: SignInForm) => {
    try {
        const response = await axios.post(`${BASE_URL}/user/auth`, form);
        const token = response.data.token;
        localStorage.setItem('jwtToken', token);
        console.log("Login successful");
    } catch (error: any) {
        throw error;
    }
}

export default signInService;
