import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import DeckItem from "../../components/Deck/DeckItem";
import { Button, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Deck } from "../../type";
import { createDeck, fetchUserDecks } from "./deckService";
import { getUserName } from "../../utils/utils";
import { isAxiosError } from "axios";

export default function Decks() {
  const [decks, setDecks] = useState<Deck[]>([]);
  const emptyDeck: Deck = {
    deck_id: -1,
    username: "",
    title: "",
    description: "",
    created_at: "",
    updated_at: "",
  }
  const [newDeck, setNewDeck] = useState<Deck>(emptyDeck);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const refresh = async () => {
    try {
      const decks: Deck[] = await fetchUserDecks(getUserName());
      setDecks(decks);
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Error occurred when fetching User decks`);
      }
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleDeckCreation = async () => {
    if (!newDeck.title.trim()) {
      alert("Deck name cannot be empty.");
      return;
    }
    try {
      await createDeck(newDeck);
      setNewDeck(emptyDeck);
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Error occurred when creating decks`);
      }
    }
    refresh();
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Box
      sx={{
        pt: 2,
        width: "60vw",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Typography sx={{ alignSelf: "flex-start" }} variant="h3">
        Decks
      </Typography>
      <List sx={{ width: "60vw" }} dense={false}>
        {decks.map((deck) => (
          <DeckItem key={deck.deck_id} deck={deck} refresh={refresh} />
        ))}
      </List>
      <Button onClick={handleOpen} variant="contained">
        Create Deck
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Deck
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            label="Deck Name"
            variant="standard"
            value={newDeck.title}
            onChange={(e) => setNewDeck({ ...newDeck, title: e.target.value })}
          />
          <Button
            sx={{ mt: 2 }}
            onClick={handleDeckCreation}
            variant="contained"
          >
            Create
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
