import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { deleteCard } from "../../pages/Study/flashcardService";
import { isAxiosError } from "axios";

interface DeleteCardProps {
  cardId: number;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteCard: React.FC<DeleteCardProps> = ({
  cardId,
  open,
  onClose,
  onDelete,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteCard(cardId);
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Error occurred when deleting cards`);
      }
    }
    onDelete();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="delete-card-modal-title"
      aria-describedby="delete-card-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="delete-card-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          {`Are you sure you want to delete this card?`}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmDelete}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteCard;
