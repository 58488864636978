import {
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FlashcardType } from "../../type";
import { createCard } from "../../pages/Study/flashcardService";
import { isAxiosError } from "axios";

interface CreateCardProps {
  open: boolean;
  onClose: () => void;
  deck_id: number;
}

const defaultTheme = createTheme();

export default function CreateCard({ open, onClose, deck_id }: CreateCardProps) {
  const emptyCard = {
    deck_id: deck_id,
    question: "",
    answer: "",
    tag: "",
  };
  const [newCard, setNewCard] = useState<FlashcardType>(emptyCard);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxHeight: "90vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleCreateCard = async () => {
    try {
      await createCard(newCard);
      setNewCard(emptyCard);
      alert("New Card created");
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Error occurred when creating cards`);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent click event from propagating
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        onClick={(e) => e.stopPropagation()} // Prevent clicks within modal content from propagating
      >
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ mb: 2 }}
        >
          Create Card
        </Typography>
        <ThemeProvider theme={defaultTheme}>
          <TextField
            label="Front"
            variant="outlined"
            fullWidth
            value={newCard?.question}
            onChange={(e) =>
              setNewCard({ ...newCard!, question: e.target.value })
            }
            multiline
            sx={{ mb: 2 }}
            rows={2}
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Back"
            value={newCard?.answer}
            onChange={(e) =>
              setNewCard({ ...newCard!, answer: e.target.value })
            }
            multiline
            sx={{ mb: 2 }}
            rows={5}
          />
        </ThemeProvider>
        <Button sx={{ mt: 2 }} variant="contained" onClick={handleCreateCard}>
          Create
        </Button>
        <Button
          sx={{ mt: 2, ml: 2 }}
          variant="outlined"
          color="error"
          onClick={(e) => {
            e.stopPropagation(); // Prevent click event from bubbling up
            onClose();
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
