import { jwtDecode } from "jwt-decode"
import { JwtPayload } from "../type";

export function isLoggedIn() {
    const token = localStorage.getItem("jwtToken");
    if (!token) return false;
    
    try {
        const decoded = jwtDecode<JwtPayload>(token);
        if (!decoded.exp) {
            console.log("Token does not have an expiration date.");
            return false;
          }
          return decoded.exp * 1000 > Date.now();
    } catch (error) {
        console.log("Invalid Token:", error);
        return false
    }
};

export function getUserName() {
    const token = localStorage.getItem("jwtToken");
    if (token) {
        try {
            const curUser = jwtDecode<JwtPayload>(token);
            console.log("User Name:", curUser.username);
            return curUser.username;
          } catch (error) {
            console.log("Invalid JWT token");
            return "";
          }
    }
    return "";
}

export function getJWTHeader() {
    return { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` };
}