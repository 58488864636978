import axios from "axios";
import { FlashcardType } from "../../type";
import { getJWTHeader } from "../../utils/utils";
import { BASE_URL } from "../base_url";

export const fetchDeckCards = async (deck_id: number) : Promise<FlashcardType[]> => {
    try {
        const response = await axios.get<FlashcardType[]>(`${BASE_URL}/flashcards/getCards/${deck_id}`, {
            headers: getJWTHeader(),
        });
        console.log("Fetch cards successful");
        return response.data;
    } catch (error: any) {
        console.error("Error when fetching cards:", error);
        throw new Error(error.response?.data?.message || "Cards retrieving failed");
    }
}

export const createCard = async (card: FlashcardType) => {
    try {
        await axios.post(`${BASE_URL}/flashcards/createCard`, card, {
            headers: getJWTHeader(),
        });
        console.log("Create card successful");
    } catch (error: any) {
        console.error("Error during card creation:", error);
        throw new Error(error.response?.data?.message || "Card creation failed");
    }
}

export const visitCard = async (card: FlashcardType) => {
    try {
        await axios.post(`${BASE_URL}/flashcards/visitCard`, card, {
            headers: getJWTHeader(),
        });
        console.log("Visit card successful");
    } catch (error: any) {
        console.error("Error during card visit:", error);
        throw new Error(error.response?.data?.message || "Card visit failed");
    }
}

export const updateCard = async (card: FlashcardType) => {
    try {
        await axios.post(`${BASE_URL}/flashcards/updateCard`, card, {
            headers: getJWTHeader(),
        });
        console.log("Update card successful");
    } catch (error: any) {
        console.error("Error during card update:", error);
        throw new Error(error.response?.data?.message || "Card update failed");
    }
}

export const deleteCard = async (card_id: number) => {
    try {
        await axios.post(`${BASE_URL}/flashcards/removeCard`, { card_id }, {
            headers: getJWTHeader(),
        });
        console.log("Delete card successful");
    } catch (error: any) {
        console.error("Error during card deletion:", error);
        throw new Error(error.response?.data?.message || "Card deletion failed");
    }
}

