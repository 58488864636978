import React from "react";
import "./App.css";
import SignIn from "./pages/SignIn/SignIn";
import Navbar from "./components/Navbar/Navbar";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./pages/Decks/Decks";
import Study from "./pages/Study/Study";
import AppTheme from "./shared-theme/AppTheme";
import SignUp from "./pages/SignUp/SignUp";
import { isLoggedIn } from "./utils/utils";

function App() {
  return (
    <AppTheme>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn() ? (
              <Navigate to="/decks" replace />
            ) : (
              <Navigate to="/signin" replace />
            )
          }
        />
        <Route path="/signin" element={<SignIn />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/decks" element={<Dashboard />}></Route>
        <Route path="/study/:id" element={<Study />}></Route>
        <Route path="*" element={<Navigate to="/" replace />}></Route>
      </Routes>
    </AppTheme>
  );
}

export default App;
