import axios from "axios";
import { BASE_URL } from "../base_url";

interface SignUpForm {
    username: string;
    password: string;
}
const signUpService = async (form: SignUpForm) => {
    try {
        const response = await axios.post(`${BASE_URL}/user/signup`, form);
        const token = response.data.token;
        localStorage.setItem('jwtToken', token);
        console.log("Signup successful");
    } catch (error: any) {
        if (axios.isAxiosError(error) && error.response) {
            const status = error.response.status;
            const message = error.response.data.error;
            if (status === 400) {
                throw { message, status };
            }
            throw { message, status };
        }
        throw new Error("An unexpected error occurred during signup.");
    }
}

export default signUpService;
