import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getUserName, isLoggedIn } from "../../utils/utils";

const defaultTheme = createTheme();

export default function Navbar() {
  const theme = useTheme();
  const settings = ["Logout"];
  const [loggedIn, setLoggedIn] = useState<boolean>(() => !!localStorage.getItem('jwtToken'));

  useEffect(() => {
    const handleAuthChange = () => {
      setLoggedIn(!!localStorage.getItem("jwtToken"));
    };

    window.addEventListener("authChange", handleAuthChange);

    return () => {
      window.removeEventListener("authChange", handleAuthChange);
    };
  }, []);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = () => {
    localStorage.removeItem("jwtToken");
    window.dispatchEvent(new Event("authChange"));

    navigate("/signin");
  };

  const handleMenuClick = (setting: string) => {
    if (setting === "Logout") {
      logOut();
    }
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{ backgroundColor: theme.palette.grey[600] }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MemoryOutlinedIcon
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            MemorWize
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            MemorWize
          </Typography>
          {loggedIn && <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              key={"Deck"}
              onClick={() => {
                navigate("/decks");
              }}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Deck
            </Button>
          </Box>}
          <Box sx={{ flexGrow: 0 }}>
            {loggedIn && (
              <ThemeProvider theme={defaultTheme}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>{getUserName()[0]}</Avatar>
                  </IconButton>
                </Tooltip>
              </ThemeProvider>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleMenuClick(setting);
                  }}
                >
                  <Typography sx={{ textAlign: "center" }}>
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
