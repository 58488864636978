import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { Deck } from "../../type";
import { deleteDeck, editDeck } from "../../pages/Decks/deckService";
import { isAxiosError } from "axios";
import { getUserName } from "../../utils/utils";
import AddIcon from "@mui/icons-material/Add";
import CreateCard from "../Card/CreateCard";
import EditIcon from "@mui/icons-material/Edit";
import { ThemeProvider } from "@mui/material";
interface DeckItemProp {
  deck: Deck;
  refresh: () => void;
}

const defaultTheme = createTheme();

export default function DeckItem({ deck, refresh }: DeckItemProp) {
  const { deck_id, title, description, created_at } = deck;
  const navigate = useNavigate();
  const [deleteDeckOpen, setDeleteDeckOpen] = useState(false);
  const [createCardOpen, setCreateCardOpen] = useState(false);
  const [editDeckOpen, setEditDeckOpen] = useState(false);

  const [editedTitle, setEditedTitle] = useState(title);
  const [editedDescription, setEditedDescription] = useState(description);

  const handleCreateCardOpen = () => setCreateCardOpen(true);
  const handleCreateCardClose = () => setCreateCardOpen(false);

  const handleDeleteDeckOpen = () => setDeleteDeckOpen(true);
  const handleDeleteDeckClose = () => setDeleteDeckOpen(false);

  const handleEditDeckOpen = () => setEditDeckOpen(true);
  const handleEditDeckClose = () => setEditDeckOpen(false);

  const handleClick = () => navigate(`/study/${deck_id}`);

  const handleDelete = async () => {
    try {
      await deleteDeck(deck_id!);
      refresh();
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Deck deletion failed!`);
      }
    } finally {
      handleDeleteDeckClose();
    }
  };

  const handleSaveEdit = async () => {
    try {
      await editDeck({
        deck_id,
        username: getUserName(),
        title: editedTitle,
        description: editedDescription,
      });
      refresh();
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(`${error.response?.data.message}`);
      } else {
        alert(`Deck deletion failed!`);
      }
    } finally {
      handleEditDeckClose();
    }
    console.log("Updated Deck:", { deck_id, editedTitle, editedDescription });
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <ListItem
        sx={{
          "&:hover": {
            backgroundColor: "lightgray",
            transition: "background-color 0.3s ease",
            cursor: "pointer",
          },
        }}
        onClick={handleClick}
        secondaryAction={
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                handleEditDeckOpen();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={(e) => {
                e.stopPropagation();
                handleCreateCardOpen();
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteDeckOpen();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        }
      >
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Box sx={{ display: "flex", gap: 1 }}>
              {description && (
                <Typography variant="body2">{description}</Typography>
              )}
              <Typography variant="caption">{`Created on: ${created_at}`}</Typography>
            </Box>
          }
        />
      </ListItem>

      {/* Create Card Modal */}
      <CreateCard
        open={createCardOpen}
        onClose={handleCreateCardClose}
        deck_id={deck_id!}
      />

      {/* Delete Deck Modal */}
      <Modal
        open={deleteDeckOpen}
        onClose={handleDeleteDeckClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`You are about to delete the following deck: ${title}`}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteDeckClose}
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained">
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Deck Modal */}
      <Modal
        open={editDeckOpen}
        onClose={handleEditDeckClose}
        aria-labelledby="edit-deck-modal-title"
        aria-describedby="edit-deck-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="edit-deck-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Edit Deck
          </Typography>
          <ThemeProvider theme={defaultTheme}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              multiline
              rows={3}
            />
          </ThemeProvider>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleEditDeckClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveEdit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
